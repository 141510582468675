<template>
  <div class="images-download p-4">
    <div v-if="images.length">
      <b-row>
        <b-col v-for="(item, index) of images" :key="index" md="3" lg="2">
          <router-link :to="`${item.id}`" append>
            <b-img
              thumbnail
              fluid
              :src="`${item.image}`"
              alt="image"
              class="mb-2"
            />
          </router-link>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="imageProcessedCount"
          :per-page="12"
          aria-controls="my-table"
          first-number
          last-number
          @input="search"
        />
      </div>
    </div>
    <p v-else class="text-center">Нет новых изображений</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      currentPage: 1,
      images: [],
      process: true
    }
  },
  computed: mapGetters('herbarium', ['imageProcessed', 'imageProcessedCount']),
  async mounted() {
    await this.$store.dispatch('herbarium/getImageProcessed', {
      page: this.currentPage,
      processed: this.process
    })
    this.images = this.imageProcessed
  },
  methods: {
    async search(value) {
      await this.$store.dispatch('herbarium/getImageProcessed', {
        page: value,
        processed: false
      })
      this.images = this.imageProcessed
    }
  }
}
</script>

<style lang="scss" scoped>
.images-download {
  background-color: #ffffff;
}
</style>
